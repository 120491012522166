import Modal from 'react-bootstrap/Modal';

const StatusModal = ({showPreview, setShowPreview, approvalLogs, data}) => {
    const handleClose = () => {
        // handlePopUp(false);
        setShowPreview(false);
    }
    return  <div>
                <Modal
                size="lg"
                show={showPreview} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Reimbursement Order No:  </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className='table table-responsive'>
                            <thead>
                                <tr>
                                    <th>Department Level</th>
                                    <th>Approval Type</th>
                                    <th>L2 Approval</th>
                                    <th>L3 Approval</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{data.department_level}</td>
                                    <td>approval</td>
                                    <td>{data.l2_approval === '1' ? 'Approved' : data.l2_approval === '0' ? 'Rejected' : 'Pending'}</td>
                                    <td>{data.l3_approval === '1' ? 'Approved' : data.l3_approval === '0' ? 'Rejected' : 'Pending'}</td>
                                </tr>
                                {
                                // (data.l2_approval === '1' && data.l3_approval === '1') && 
                                approvalLogs.map((log, idx) => {
                                    let logs = approvalLogs[idx - 1];
                                    if(logs) {
                                        return (
                                            <tr key={idx}>
                                                <td>{log.department_level}</td>
                                                <td>{log.approval_type}</td>
                                                <td>{((logs.l2_approval == "1" && logs.l3_approval == "1")) ? log.l2_approval == '1' ? 'Approved' : log.l2_approval == '0' ? 'Rejected' : 'Pending' : '-'}</td>
                                                <td>{((logs.l2_approval == "1" && logs.l3_approval == "1")) ? log.l3_approval == '1' ? 'Approved' : log.l3_approval == '0' ? 'Rejected' : 'Pending' : '-'}</td>
                                            </tr>
                                        )
                                    } else {
                                        return (
                                            <tr key={idx}>
                                                <td>{log.department_level}</td>
                                                <td>{log.approval_type}</td>
                                                <td>{(data.l2_approval === '1' && data.l3_approval === '1') ? log.l2_approval === '1' ? 'Approved' : log.l2_approval === '0' ? 'Rejected' : 'Pending' : '-' }</td>
                                                <td>{(data.l2_approval === '1' && data.l3_approval === '1') ? log.l3_approval === '1' ? 'Approved' : log.l3_approval === '0' ? 'Rejected' : 'Pending' : '-'}</td>
                                            </tr>
                                        )
                                    }

                                    
                                }
                                )
                                }
                            </tbody>
                        </table>
                    </Modal.Body>

                </Modal>
            </div>
}

export default StatusModal;